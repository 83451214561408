
import { CALL_API } from '../utils/HTTP';
import backendUrls from '../utils/BackendUrls';

const ContactService = {
  send: async function (data) {
      const url = backendUrls.MAILER.SEND, secure = false, cache = false;
      return await CALL_API("POST", url, data, secure, cache);
  }
};

export default ContactService;