 import {getBearerAccessToken} from '../services/Auth';
import Cookies from 'js-cookie';
import { urlToSlug } from './helpers';
import BackendUrls from './BackendUrls';

const cachePrefix = '_cached-data-';

// using Java Script method to get PDF file
const downloadFile = (url, fileName, data) => {
    fetch(url, {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
        },
        "body": JSON.stringify(data)
    }).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        })
    });
};

const uploadFile = async (file, url = BackendUrls.uploadFileURL) =>{
    const formData = new FormData();
    formData.append('file', file);
    const secure = true, cache = false;
    return await CALL_API('UPLOAD', url, formData, secure, cache)
}

const DELETE = async (url, data, { secure = true, cache = false }) => {
    
    const token = secure ? getBearerAccessToken() : null;
    const options = {
        "content-type": "application/json",
        "accept": "application/json",
    }

    if (secure) options['Authorization'] = "Bearer " + token

    if (cache) {
        const responseCached = getCachedResponse(url)
        if (responseCached) return responseCached
    }

    return await fetch(url, {
        "method": "DELETE",
        "headers": options,
        "body": JSON.stringify(data)
    }).then(response => {

        if (!response.ok) {
            // Manually throw an error if the HTTP status code indicates a failure
            return manuallyThrowError(response)
        }

        setCacheResponse(url, cache, response)
        response.fromCache = false;
        return response;
    });
};

const PUT = async (url, data, { secure = true, cache = false }) => {
    
    const token = secure ? getBearerAccessToken() : null;
    const options = {
        "content-type": "application/json",
        "accept": "application/json",
    }

    if (secure) options['Authorization'] = "Bearer " + token

    if (cache) {
        const responseCached = getCachedResponse(url)
        if (responseCached) return responseCached
    }

    return await fetch(url, {
        "method": "PUT",
        "headers": options,
        "body": JSON.stringify(data)
    }).then(response => {

        if (!response.ok) {
            // Manually throw an error if the HTTP status code indicates a failure
            return manuallyThrowError(response)
        }

        setCacheResponse(url, cache, response)
        response.fromCache = false;
        return response;
    });
};

const POST = async (url, data, { secure = true, cache = false }) => {
    
    const token = secure ? getBearerAccessToken() : null;
    const options = {
        "content-type": "application/json",
        "accept": "application/json",
    }

    if (secure) options['Authorization'] = "Bearer " + token

    if (cache) {
        const responseCached = getCachedResponse(url)
        if (responseCached) return responseCached
    }

    return await fetch(url, {
        "method": "POST",
        "headers": options,
        "body": JSON.stringify(data)
    }).then(async response => {

        if (!response.ok) {
            // Manually throw an error if the HTTP status code indicates a failure
            return await manuallyThrowError(response)
        }

        setCacheResponse(url, cache, response)
        response.fromCache = false;
        return response;
    });
};

const UPLOAD = async (url, data, { secure = true, cache = false }) => {
    
    const token = secure ? getBearerAccessToken() : null;
    const options = {}

    if (secure) options['Authorization'] = "Bearer " + token

    if (cache) {
        const responseCached = getCachedResponse(url)
        if (responseCached) return responseCached
    }

    return await fetch(url, {
        "method": "POST",
        "headers": options,
        "body": data
    }).then(async response => {

        if (!response.ok) {
            // Manually throw an error if the HTTP status code indicates a failure
            return await manuallyThrowError(response)
        }

        setCacheResponse(url, cache, response)
        response.fromCache = false;
        return response;
    });
};

const GET = async (url, { secure = true, cache = false }) => {

    const token = secure ? getBearerAccessToken() : null;
    const options = {
        "content-type": "application/json",
        "accept": "application/json",
    }

    if (secure) options['Authorization'] = "Bearer " + token

    if (cache) {
        const responseCached = getCachedResponse(url)
        if (responseCached) return responseCached
    }

    return await fetch(url, {
        "method": "GET",
        "headers": options,
    }).then(response => {
        
        if (!response.ok) {
            // console.log('response fetch: ', response);
            // Manually throw an error if the HTTP status code indicates a failure
            return manuallyThrowError(response)
        }

        setCacheResponse(url, cache, response)
        response.fromCache = false;
        return response;
    });
};

const manuallyThrowError = async (response) =>{
    return await response.json().then(err => {
        const error = new Error(response.statusText);
        error.response = err;
        throw error;
    });
}

const getCachedResponse = (url) => {
    const cachedResponse = Cookies.get(cachePrefix+urlToSlug(url));
    if (cachedResponse) {
        const parsedResponse = JSON.parse(cachedResponse);
        const response = new Response(JSON.stringify(parsedResponse.body), {
            status: parsedResponse.status,
            statusText: parsedResponse.statusText,
            headers: new Headers(parsedResponse.headers)
        });
        response.fromCache = true;
        return Promise.resolve(response);
    }
    return null
}

const setCacheResponse = (url, cache, response) => {
    // console.log('response: ', response);
    if (response.ok && cache) {
        response.clone().json().then(body => {
            const cacheData = {
                body: body,
                status: response.status,
                statusText: response.statusText,
                headers: {},
                url: response.url
            };
            response.headers.forEach((value, name) => {
                cacheData.headers[name] = value;
            });
            Cookies.set(cachePrefix+urlToSlug(url), JSON.stringify(cacheData), { expires: 1 }); //  1 day
            // console.log('JSON.stringify(cacheData): ', JSON.stringify(cacheData));
        });
    }
}

const CALL_API = async (method, url, data = null, secure = false, cache = false)=>{
    let resp = {status: 'success', code: null, data: null};
    switch (method) {
        case 'GET':
            await GET(url, {secure, cache}).then(async response => {
                resp.code = response.status
                resp.fromCache = response.fromCache
                const responseBody = await response.text();
                if (responseBody) {
                    return JSON.parse(responseBody);
                }
                return null
            })
            .then(data => {
                resp.data = data;
            })
            .catch(error => {
                if (error.response) {
                    resp = getServerError(error)
                } else {
                    resp = getInternalError(error)
                }
            });
            return resp;
        case 'PUT':
            await PUT(url, data, {secure, cache}).then(async response => {
                resp.code = response.status
                resp.fromCache = response.fromCache
                const responseBody = await response.text();
                if (responseBody) {
                    return JSON.parse(responseBody);
                }
                return null
            })
            .then(data => {
                resp.data = data;
            })
            .catch(error => {
                if (error.response) {
                    resp = getServerError(error)
                } else {
                    resp = getInternalError(error)
                }
            });
            return resp;
        case 'DELETE':
            await DELETE(url, data, {secure, cache}).then(async response => {
                resp.code = response.status
                resp.fromCache = response.fromCache
                const responseBody = await response.text();
                if (responseBody) {
                    return JSON.parse(responseBody);
                }
                return null
            })
            .then(data => {
                resp.data = data;
            })
            .catch(error => {
                if (error.response) {
                    resp = getServerError(error)
                } else {
                    resp = getInternalError(error)
                }
            });
            return resp;
        case 'POST':
            await POST(url, data, {secure, cache}).then(async response => {
                resp.code = response.status
                resp.fromCache = response.fromCache
                const responseBody = await response.text();
                if (responseBody) {
                    return JSON.parse(responseBody);
                }
                return null
            })
            .then(data => {
                resp.data = data;
            })
            .catch(error => {
                if (error.response) {
                    resp = getServerError(error)
                } else {
                    resp = getInternalError(error)
                }
            });
            return resp;
        case 'UPLOAD':
            await UPLOAD(url, data, {secure, cache}).then(async response => {
                resp.code = response.status
                resp.fromCache = response.fromCache
                const responseBody = await response.text();
                if (responseBody) {
                    return JSON.parse(responseBody);
                }
                return null
            })
            .then(data => {
                resp.data = data;
            })
            .catch(error => {
                if (error.response) {
                    resp = getServerError(error)
                } else {
                    resp = getInternalError(error)
                }
            });
            return resp;
    
        default:
            break;
    }
}

const getServerError = (error) =>{
    let resp = {status: null, code: null, data: null};
    resp.status = 'error.server'
    resp.code = error.response.status
    resp.data = error.response
    return resp;
}

const getInternalError = (error) =>{
    let resp = {status: null, code: null, data: null};
    console.log('internal error: ', error);
    resp.status = 'error'
    resp.code = 0
    resp.data = error
    return resp;
}

export {
    GET,
    POST,
    PUT,
    DELETE,
    CALL_API,
    downloadFile,
    manuallyThrowError,
    uploadFile
};