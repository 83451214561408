
function WhatIsUtrains() {

    return (
        <>
            <section className="testimonial-area-6 section-gap-equal">
                <div className="container edublink-animated-shape">
                    <div className="row row--40">
                        <div className="col-lg-6">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title">About Us</span>
                                <h2 className="title">What is Utrains ?</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                            </div>
                            <div className="testimonial-activation-5 swiperr">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slidee">
                                        <div className="testimonial-slide testimonial-style-3">
                                            <div className="content">
                                                <p>
                                                    Utrains is an innovative learning platform that trains DevOps engineers, Cloud engineers, and system administrators. It offers a comprehensive and affordable curriculum focused on practical skills, while also providing support for job search and interview preparation. Utrains makes computer science education accessible to everyone and prepares its learners to succeed in the tech industry.
                                                </p>
                                                <p>
                                                    Want to know more about the training? Join our free Webinar
                                                </p>
                                                <div className="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                                    <a href="/sessions/next" className="edu-btn">Register for the Webinar<i className="icon-4"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="video-gallery video-gallery-5" data-sal-delay="150" data-sal="slide-left" data-sal-duration="800">
                                <div className="thumbnail">
                                    <img src="assets/images/others/video-03.png" alt="Thumb"/>
                                    <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="video-play-btn video-popup-activation">
                                        <i className="icon-18"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <h4 className="title">Take a Video Tour to Learn about Utrains</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group">
                        <li className="shape-2 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                            <img data-depth="2" src="assets/images/about/shape-25.png" alt="Shape"/>
                        </li>
                        <li className="shape-3 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                            <span data-depth="-1"></span>
                        </li>
                    </ul>
                </div>
                <ul className="shape-group">
                    <li className="shape-1" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                        <img className="rotateit" src="assets/images/about/shape-13.png" alt="Shape"/>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default WhatIsUtrains
