
import React, { useState } from 'react';
import ContactService from '../../../services/ContactService';

import { validate } from "../../../utils/helpers";

function ContactForm() {

  const [errors, setErrors] = useState({
    senderName: '',
    senderEmail: '',
    title: '',
    content: ''
  });
  const [contactData, setContactData] = useState({
    senderName: '',
    senderEmail: '',
    title: '',
    content: ''
  });
  const [isLoading, setIsLoading] = useState({
    sendContactForm: { value: false},
  });

  const [alerts, setAlerts] = useState(null);

  const resetContactData = () => {
    setContactData({
      senderName: '',
      senderEmail: '',
      title: '',
      content: ''
    });
  };

  const validationRules = {
    senderName: { required: true },
    senderEmail: { required: true },
    title: { required: true },
    content: { required: true },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value
    });
  };

  const sendContactForm = async () => {
    setErrors({});
    setIsLoading(prevState => ({
      ...prevState,
      sendContactForm: {value: true}
    })); 
    const validationErrors = validate(contactData, validationRules);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const response = await ContactService.send(contactData);
      if (response.status === 'success') {
        setAlerts(
          <p style={{ color: 'green' }}>Message sent successfully</p>
        );
        resetContactData();
      } else if (response.status === 'error.server') {
        setAlerts(
          <p style={{ color: 'red' }}>{response.data?.message ?? 'Unknown Server Error'}</p>
        );
      }
    } else {
      setAlerts(
        <p style={{ color: 'red' }}>Please fill in all required fields</p>
      );
    }

    setIsLoading(prevState => ({
      ...prevState,
      sendContactForm: {value: false}
    })); 
  };

  return (
    <div className="contact-form form-style-2">
      <div className="section-title">
        <h4 className="title">Get In Touch</h4>
        <p>Please get in touch and we'll be glad to help.</p>
      </div>
      <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" onSubmit={(e) => e.preventDefault()}>
        <div className="row row--10">
          <div className="form-group col-12">
            <input type="text" name="senderName" id="senderName" placeholder="Your name" value={contactData.senderName} onChange={handleInputChange} />
            {errors.senderName && <small className="form-text text-danger">{errors.senderName}</small>}
          </div>
          <div className="form-group col-12">
            <input type="email" name="senderEmail" id="senderEmail" placeholder="Enter your email" value={contactData.senderEmail} onChange={handleInputChange} />
            {errors.senderEmail && <small className="form-text text-danger">{errors.senderEmail}</small>}
          </div>
          <div className="form-group col-12">
            <input type="text" name="title" id="title" placeholder="Title" value={contactData.title} onChange={handleInputChange} />
            {errors.title && <small className="form-text text-danger">{errors.title}</small>}
          </div>
          <div className="form-group col-12">
            <textarea name="content" id="content" cols="30" rows="4" placeholder="Your message" value={contactData.content} onChange={handleInputChange}></textarea>
            {errors.content && <small className="form-text text-danger">{errors.content}</small>}
          </div>
          <div className="form-group col-12">
            <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="button" onClick={sendContactForm}>
              { isLoading?.sendContactForm?.value ? "Sending..." : "Submit Message"}  <i className="icon-4"></i>
            </button>
            {alerts}
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;