
function AdvantagesSection() {

    return (
        <>
            <div className="gap-bottom-equal edu-about-area about-style-1">
                <div className="container edublink-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image-gallery video-prof-box">
                                <div className="thumb">
                                    <img src="/assets/images/about/about-01-1.webp" alt="About img"/>
                                    <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="popup-icon video-popup-activation" id='video-popup-activation'>
                                        <i className="icon-18"></i>
                                    </a>
                                </div>
                                
                                <div className="award-status bounce-slide">
                                    <div className="inner">
                                        <div className="icon">
                                            <i className="icon-21"></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">29+</h6>
                                            <span className="subtitle">Wonderful Awards</span>
                                        </div>
                                    </div>
                                </div>
                                <ul className="shape-group">
                                    <li className="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="1" src="/assets/images/about/shape-36.png" alt="Shape"/>
                                    </li>
                                    <li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-1" src="/assets/images/about/shape-37.png" alt="Shape"/>
                                    </li>
                                    <li className="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="1" src="/assets/images/about/shape-02.png" alt="Shape"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6" data-sal-delay="150" data-sal="slide-left" data-sal-duration="800">
                            <div className="about-content">
                                <div className="section-title section-left">
                                    <span className="pre-title">Why Us ?</span>
                                    <h2 className="title">What is this <span className="color-secondary">training </span>about?</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                    <p>Computers have become essential in our daily lives. More and more companies rely on engineers to develop various applications. However, few of those engineers have the necessary skills to make an application in development status to an application available online for end users. The main goal of this training is to teach you all the steps necessary to put an application online (deployment). With these skills, you will be able to join any development team anywhere in the world. Learn more by listening to the video on the left.</p>
                                </div>
                                <ul className="features-list">
                                    <li>Expert Trainers</li>
                                    <li>Online Remote Learning</li>
                                    <li>Lifetime Access</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group">
                        <li className="shape-1 circle scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            {/* <span data-depth="-2.3"></span> */}
                            <img data-depth="1.6" src="/assets/images/counterup/shape-07.png" alt="Shape"/>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AdvantagesSection
