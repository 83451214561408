import { useState, useEffect } from "react";
import { isAuthenticated } from "../../services/Auth";
import { Link } from "react-router-dom";
import HeaderTop from "./HeaderTop";
import HeaderCategory from "./HeaderCategory";
import routesPath from '../../routes/routesPath';
import Dropdown from "./Dropdown";

// import HeaderTopbar from './HeaderTopbar';


function Header() {
   
    const paymentPlatformUrl = process.env.REACT_APP_STOREFRONT;
    const handleLoginRedirect = (event) => {
        event.preventDefault(); 
        window.location.href = paymentPlatformUrl; // Redirige vers l'URL
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
   

    useEffect(() => {
        setIsLoggedIn(isAuthenticated());
    }, []);
    
    return (
        <header className="edu-header header-style-1 header-fullwidth">
            <HeaderTop />
            <div id="edu-sticky-placeholder"></div>
  
            <div className="header-mainmenu">
                <div className="container-fluid">
                    <div className="header-navbar">
                        <div className="header-brand">
                            <div className="logo">
                                <Link to="/">
                                    <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                                    <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                                </Link>
                            </div>
                            {/* <HeaderCategory /> */}
                        </div>
                        <div className="header-mainnav">
                            <nav className="mainmenu-nav">
                                <ul className="mainmenu">
                                    <li className=""><a href={routesPath.home}>Home</a></li>
                                    <li className=""><Link to="/faq">FAQ</Link></li>
                                    <li className=""><Link to="/testimonials">Testimonials</Link></li>
                                    <li className=""><Link to="/feedback">FeedBack</Link></li>
                                    <li className=""><Link to="/contact_us">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            { isLoggedIn ? (
                              <Dropdown/>
                            ) : (
                            <ul className="header-action utrains-header-action">
                                <li className="header-btn">
                                    <a href={paymentPlatformUrl} className="edu-btn btn-medium" onClick={handleLoginRedirect} target="_blank" rel="noopener noreferrer">Login <i className="icon-4"></i></a>
                                </li>
                                <li className="header-btn">
                                <a href={paymentPlatformUrl} className="edu-btn btn-medium" onClick={handleLoginRedirect} target="_blank" rel="noopener noreferrer">Sign up <i className="icon-4"></i></a>
  
                                </li>
                                <li className="mobile-menu-bar d-block d-xl-none">
                                    <button className="hamberger-button">
                                        <i className="icon-54"></i>
                                    </button>
                                </li>
                            </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-mobile-menu">
                <div className="inner">
                    <div className="header-top">
                        <div className="logo">
                            <Link to="/">
                                <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                                <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                            </Link>
                        </div>
                        <div className="close-menu">
                            <button className="close-button">
                                <i className="icon-73"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="mainmenu">
                        <li className=""><a href="/">Home</a></li>
                        <li className=""><Link to="/faq">FAQ</Link></li>
                        <li className=""><Link to="/testimonials">Testimonials</Link></li>
                        <li className=""><Link to="/feedback">FeedBack</Link></li>
                        <li className=""><Link to="/contact_us">Contact</Link></li>
                    </ul>
                </div>
            </div>
  
            <div className="edu-search-popup">
                <div className="content-wrap">
                    <div className="site-logo">
                        <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                        <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                    </div>
                    <div className="close-button">
                        <button className="close-trigger"><i className="icon-73"></i></button>
                    </div>
                    <div className="inner">
                        <form className="search-form" action="#">
                            <input type="text" className="edublink-search-popup-field" placeholder="Search Here..." />
                            <button className="submit-button"><i className="icon-2"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </header>
    )
  }
  
  export default Header
