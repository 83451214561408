
function AboutInstructor() {

    return (
        <>
        <div className="edu-team-details-area utrains-section-gap-bottom">
            <div className="container">
                <div className="row row--40">
                    <div className="col-lg-4">
                        <div className="team-details-thumb">
                            <div className="thumbnail">
                                <img src="assets/images/team/team-18.png" alt="team"/>
                            </div>
                            <ul className="utrains-banner-btn-video" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                    <li className="video-btn">
                                        <a href="https://www.youtube.com/watch?v=a1PGblelAbM" className="popup-icon video-popup-activation">
                                            <i className="icon-18"></i>
                                            <span>Watch his Story Now</span>
                                        </a>
                                    </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="team-details-content">
                            <div className="main-info">
                                <span className="subtitle">Discover our Lead Instructor</span>
                                <h3 className="title">Serge Kamgang</h3>
                                <span className="designation">Senior DevOps Engineer</span>
                            </div>
                            <div className="bio-describe">
                                <p>
                                  Serge Kamgang is a Senior DevOps Engineer with over 10 years of experience, having worked with several American companies. With no initial training in IT, Serge began his career in data warehouses, struggling to support his family by working two or three jobs. His life changed the day he discovered an IT training course, which opened the door to a career change. A year later, he landed a position as a systems administrator at Bank of America, quadrupling his income and achieving financial freedom.
                                </p>
                                <p>
                                  In 2016, driven by the desire to share his knowledge and help those in a similar situation to him, Serge founded Utrains. His goal is to enable others to develop in the field of IT and to flourish financially. Since then, he has trained several thousand people, helping them become qualified DevOps engineers and transform their professional lives.
                                </p>
                                <p>
                                  Don’t wait any longer to change your life like Serge did! Register now for Utrains training and take the first step towards a rewarding career and financial freedom. Join a community of thousands of people who have already found success in IT thanks to Utrains. Your future starts here.
                                </p>
                                
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AboutInstructor
